import React from 'react'
import {useState, useRef, useEffect} from 'react'
import TechAppDialog from '../TechAppDialog'
import { FormGroup } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Checkbox from '@material-ui/core/Checkbox'
import SelectItems from '../editors/SelectItemsComponent'
import FormHelperText from '@material-ui/core/FormHelperText'
import OdooDatabase from '../../data/odoo'
import Loading from '../LoadingComponent'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import '../css/actionrequired.css'

type ActionRequiredDialogProps = {
    workorder_id: number;
    asset_id: number;
    assets: any[];
    category_id: number;
    open: boolean;
    onError?(errorMsg: string): void;
    onClose(): void;
    onOK(): void;
}

export default function ActionRequiredDialog(props: ActionRequiredDialogProps){
    const [action_type, setActionType] = useState('quote')
    const [asset_state, setAssetState] = useState('')
    const [addRepairProc, setAddRepairProc] = useState(false)
    const [procedureIds, setProcedureIds] = useState<number[]>([])
    const [updating, setUpdating] = useState(false)
    const [loading, setLoading] = useState(false)

    const [selectedAsset, setSelectedAsset] = useState(0)

    const assetStateValid = action_type !== 'quote' || asset_state !== '';
    const assetValid = action_type !== 'quote' || selectedAsset !== 0;


    const onCreateActionClick = async () => {
        if(!assetStateValid || !assetValid){
            return
        }
        let odoo = OdooDatabase.getInstance();
        setUpdating(true)
        try{
            await odoo.execute_kw("fieldservice.followup", "create_followup_wo", [[{
                wo_id: props.workorder_id,
                follow_up_type: action_type,
                auto_repair_procedure: addRepairProc,
                extra_procs: procedureIds,
                asset_id: selectedAsset,
                asset_state: asset_state,
            }]])
            setUpdating(false)
            alert('Action Required Created')
            props.onOK()
        }catch(err: any){
            setUpdating(false)
            console.log('Error', err)
            if(props.onError)
                props.onError(err.faultString)
        }
    }

    const items = props.assets.map((asset: any) => {
        return (<MenuItem key={`asset_id${asset.id}`} value={asset.id}>{`${asset.name} - ${asset.category} (SN: ${asset.sn})`}</MenuItem>)
    })
    return (
        <>
        <TechAppDialog open={props.open} onClose={props.onClose} title="Action Required"
            buttons={[{text: 'Create Action Required', onClick:onCreateActionClick, primary: true},
                     {text: 'Close', onClick: props.onClose}
                     ]}
        >
            {
                loading?
                (<Loading loading={updating}/>):
                (<form className="dialog-form">
                    <FormGroup row className="boxed_group">
                        <FormControl>
                            <FormLabel component="legend">Action Type</FormLabel>
                            <RadioGroup row aria-label="Action Type" name="action_type" value={action_type} onChange={(event:any) => {setActionType(event.target.value)}}>
                                <FormControlLabel
                                    value="quote"
                                    control={<Radio color="primary" />}
                                    label="Quote Request"
                                />
                                <FormControlLabel
                                    value="workorder"
                                    control={<Radio color="primary" />}
                                    label="Completed On-site WO"
                                />
                            </RadioGroup>
                        </FormControl>
                    </FormGroup>
                    {
                    action_type === 'quote' ? (<>
                    <FormGroup row className="boxed_group">
                        <FormControl size="small">
                            <InputLabel id="part-to-add">Asset</InputLabel>
                            <Select fullWidth value={selectedAsset} name="selected_asset" onChange={(event:any) => {setSelectedAsset(event.target.value)}}>
                                {items}
                            </Select>
                            {(!assetValid)?(<FormHelperText>Select Asset</FormHelperText>):(<></>)}
                        </FormControl>
                    </FormGroup>
                    <FormGroup row className="boxed_group">
                        <FormControl>
                            <FormLabel component="legend">Asset State</FormLabel>
                            <RadioGroup row aria-label="Asset State" name="asset_state" value={asset_state} onChange={(event:any) => {setAssetState(event.target.value)}}>
                                <FormControlLabel
                                    value="red"
                                    control={<Radio color="primary" />}
                                    label="Red"
                                />
                                <FormControlLabel
                                    value="yellow"
                                    control={<Radio color="primary" />}
                                    label="Yellow"
                                />
                                <FormControlLabel
                                    value="green"
                                    control={<Radio color="primary" />}
                                    label="Green"
                                />
                            </RadioGroup>
                            {(!assetStateValid)?(<FormHelperText>Select Asset State</FormHelperText>):(<></>)}
                        </FormControl>
                    </FormGroup></>) : (<></>)
                    }
                    <FormGroup row className="boxed_group">
                        <FormControlLabel
                            control={<Checkbox checked={addRepairProc} onChange={() => {setAddRepairProc(!addRepairProc)}} name="addRepairProc"
                            color="primary"
                            />}
                            label="Add Repair Proc"
                        />
                    </FormGroup>
                    <FormGroup row className="boxed_group">
                        <FormControl>
                            <FormLabel component="legend">Procedures to Add to Follow Up</FormLabel>
                            <SelectItems
                                model="fieldservice.procedure"
                                fields={['name', 'pcategory_id']}
                                itemName="Procedure"
                                pluralItemName="Procedures"
                                className="select_procedure_list"
                                selectedItems={procedureIds}
                                onChange={(newIds: number[]) => {setProcedureIds(newIds)}}
                                elements={[{field: 'name', type: 'text', header: 'Procedure'},
                                        {field: 'pcategory_id', type: 'many2one', header: 'Category'}]}
                                domain={[['pcategory_id', '=', props.category_id]]}
                                hideTableHead={true}
                            />
                        </FormControl>
                    </FormGroup>
                </form>)
            }
        </TechAppDialog>
        </>
    )
}